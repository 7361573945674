<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" class="py-5" v-if="fetchingCapitalEntryList">
          <div class="text-center text-muted">
            <b-spinner></b-spinner>
            <br>
            Chargement de la liste des entrées de fond...
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-layers mr-2"></i>
              <strong>
                Liste des Entrées
              </strong>
              <div class="card-header-actions">
                <b-button variant="primary" id="createEntry" @click="newCapitalEntry">
                  <i class="fa fa-plus"></i>
                  Enregistrer une entrée
                </b-button>
                <b-modal title="Enregistrer une entrée de fond" class="modal-primary"
                         v-model="addModalCapitalEntry"
                         @hide="onAddModalCapitalEntryClose" size="lg">
                  <b-form-row>
                    <b-col class="mr-4">
                      <c-input container-class="mb-3" label="Libellé de l'entrée de fond" placeholder="Ex: Entrée 1"
                               v-model="capitalEntryName" :state="capitalEntryNameState">
                        Veuillez saisir un nom
                      </c-input>
                      <c-input container-class="mb-3" type="quantity" label="Coût de l'entrée de fond"
                               placeholder="Ex: 50000"
                               v-model="capitalEntryAmount" :unit="exploitationCurrency"
                               step="500" :state="capitalEntryAmountState">
                        Veuillez spécifier un coût
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-3" type="select" label="Moyen de l'entrée de fond"
                                 v-model="capitalEntryMode"
                                 :options="listCapitalEntryModes" :state="capitalEntryModeState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez la catégorie de l'entrée --</option>
                          </template>
                          Veuillez sélectionner une catégorie
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouveau moyen"
                                  @click="addCapitalEntryMode" :disabled="capitalEntryModeNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouveau moyen"
                               v-model="capitalEntryModeNew" :state="capitalEntryModeState"
                               v-if="capitalEntryModeNewDisplay">
                        Veuillez saisir un moyen
                      </c-input>
                      
                      
                      <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement"
                               v-model="capitalEntryDate"
                               :time.sync="capitalEntryTime">
                        Veuillez entrer une durée
                      </c-input>
                    </b-col>
                    <b-col>
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Emetteur de l'entrée de fond"
                                 v-model="capitalEntryEmitter"
                                 :options="capitalEntriesMembersList" :state="capitalEntryEmitterState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez l'emetteur --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addCapitalEntryEmitter" :disabled="capitalEntryEmitterNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouvel emetteur"
                               v-model="capitalEntryEmitterNew" :state="capitalEntryEmitterState"
                               v-if="capitalEntryEmitterNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Bénéficiaire de l'entrée de fond"
                                 v-model="capitalEntryReceiver"
                                 :options="capitalEntriesMembersList" :state="capitalEntryReceiverState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez le bénéficiaire --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addReceiveEmitter" :disabled="capitalEntryReceiverNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouveau bénéficiaire"
                               v-model="capitalEntryReceiverNew" :state="capitalEntryReceiverState"
                               v-if="capitalEntryReceiverNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="textarea" label="Description de l'entrée de fond"
                               placeholder="Entrez une description..." v-model="capitalEntryDescription">
                      </c-input>
                    </b-col>
                    <b-form-file
                      v-model="capitalEntryFiles"
                      placeholder="Choisissez un fichier et déposez le ici..."
                      drop-placeholder="Déposez ici..."
                      multiple
                    ></b-form-file>
                  </b-form-row>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAddCapitalEntry"
                                    :fetching=fetchingCreateCapitalEntry>
                      Enregistrer
                    </button-spinner>
                    <b-button variant="secondary" @click="addModalCapitalEntry = editModalCapitalEntry = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              </div>
            </div>
            <b-row>
              <div class="px-2 w-100">
                <!-- Tabs with card integration -->
                <b-card no-body>
                  <b-tabs card v-model="capitalEntry">
                    <b-tab>
                      <template slot="title">
                        <div id="v-tab-0">
                          Entrée de fonds ({{capitalEntriesData.length}})
                        </div>
                      </template>
                      <c-table :table-data="capitalEntriesData" :fields="capitalEntriesFields"
                               :actions="capitalEntriesActions"
                               :per-page=5 striped
                               outlined>
                        <template slot="empty">
                          <p class="text-center">Aucun entrée de fond dans exploitation.</p>
                        </template>
                      </c-table>
                    </b-tab>
                    <b-tab class="">
                      <template slot="title">
                        <div id="v-tab-1">
                          Autres entrées ({{capitalOtherEntriesData.length}})
                          <info-message v-if="capitalEntry==1">
                            Elles concernent essentiellement les sous-ventes
                          </info-message>
                        </div>
                      </template>
                      <b-jumbotron class="text-center text-muted py-4" v-if="fetchingCapitalOtherEntryList">
                        <b-spinner></b-spinner>
                        <br>
                        Chargement de la liste des autres entrées...
                      </b-jumbotron>
                      <c-table :table-data="capitalOtherEntriesData" :fields="capitalOtherEntriesFields"
                               :actions="capitalOtherEntriesActions"
                               :per-page=10 striped
                               outlined fixed v-else>
                        <template slot="empty">
                          <p class="text-center">Aucun entrée de fond dans exploitation.</p>
                        </template>
                      </c-table>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, price, Regex, Toast} from "../../../helpers";
  
  export default {
    name: "AccountingInputs",
    title: "PIA - Entrées",
    data() {
      return {
        fetchingCapitalEntryList: false,
        fetchingCapitalOtherEntryList: false,
        
        capitalEntry: 0,
        
        capitalEntries: [],
        capitalEntriesFields: [
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'name', label: 'Libellé de l\'entrée de fond'},
          {key: 'amountCurrency', label: 'Montant'},
          {key: 'entryEmitter.name', label: 'Effectuée par'},
          {key: 'actions', label: 'Actions'},
        ],
        capitalEntriesActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.capitalEntryDetails},
        ],
        
        capitalOtherEntries: [],
        capitalOtherEntriesFields: [
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'name', label: 'Libellé de l\'entrée'},
          {key: 'priceCurrency', label: 'Montant'},
          {key: 'user.name', label: 'Vendu par'},
          {key: 'actions', label: 'Actions'},
        ],
        capitalOtherEntriesActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.capitalEntryDetails},
        ],
        
        
        addModalCapitalEntry: false,
        editModalCapitalEntry: false,
        
        capitalEntriesMembers: [],
        capitalEntryEmitterNewDisplay: false,
        capitalEntryReceiverNewDisplay: false,
        
        capitalEntryModes: [],
        
        capitalEditId: 0,
        
        capitalEntryName: '',
        capitalEntryAmount: '',
        
        capitalEntryMode: null,
        capitalEntryModeNewDisplay: false,
        capitalEntryModeNew: "",
        
        capitalEntryDate: "",
        capitalEntryTime: "",
        capitalEntryEmitter: null,
        capitalEntryReceiver: null,
        capitalEntryEmitterNew: "",
        capitalEntryReceiverNew: "",
        capitalEntryDescription: '',
        fetchingCreateCapitalEntry: false,
        submittedCapitalEntry: false,
        capitalEntryFiles: [],
        
      }
    },
    created() {
      this.fetchingCapitalEntryList = true
      Api.get('/exploitation/capital-entry/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.capitalEntries = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCapitalEntryList = false
        })
      Api.get('/exploitation/member/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.capitalEntriesMembers = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/payment-mode/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.capitalEntryModes = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      
      this.fetchingCapitalOtherEntryList = true
      Api.get('/exploitation/under-sale/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.capitalOtherEntries = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCapitalOtherEntryList = false
          this.$nextTick(()=>{
            this.$tours['myTour'].start()
          })
        })
      
    },
    mounted() {
    
    },
    watch: {},
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      exploitationCurrency() {
        return price()
      },
      capitalEntriesData() {
        return this.capitalEntries.map(capitalEntry => ({
          ...capitalEntry,
          amountCurrency: capitalEntry.amount + ' ' + this.exploitationCurrency,
          // todo i've remove the hour and the minutes when displaying the time but they are still available.
          formattedDate: capitalEntry.date.split(' ')[0]
        }))
      },
      capitalOtherEntriesData() {
        return this.capitalOtherEntries.map(capitalOtherEntry => ({
          ...capitalOtherEntry,
          priceCurrency: capitalOtherEntry.price + ' ' + this.exploitationCurrency,
          formattedDate: capitalOtherEntry.saleDate.split(' ')[0]
        }))
      },
      capitalEntryNameState() {
        return !this.submittedCapitalEntry || this.capitalEntryName.trim().length >= 3 ? null : false
      },
      capitalEntryAmountState() {
        return !this.submittedCapitalEntry || Regex.isPositiveNumber(this.capitalEntryAmount) ? null : false
      },
      capitalEntryModeState() {
        return !this.submittedCapitalEntry || (this.capitalEntryMode != null || this.capitalEntryModeNew.trim().length >= 3) ? null : false
      },
      capitalEntryEmitterState() {
        return !this.submittedCapitalEntry || (this.capitalEntryEmitter != null || this.capitalEntryEmitterNew.trim().length >= 3) ? null : false
      },
      capitalEntryReceiverState() {
        return !this.submittedCapitalEntry || (this.capitalEntryReceiver != null || this.capitalEntryReceiverNew.trim().length >= 3) ? null : false
      },
      listCapitalEntryModes() {
        return this.capitalEntryModes.map(cat => ({
          text: cat.name,
          value: cat
        }))
      },
      capitalEntriesMembersList() {
        return this.capitalEntriesMembers.map(member => ({
          text: member.name,
          value: member
        }))
      }
    },
    methods: {
      
      onAddModalCapitalEntryClose() {
        
        this.editModalCapitalEntry = false
        this.capitalEntryName = ''
        this.capitalEntryAmount = ''
        this.capitalEntryDescription = ''
        this.submittedCapitalEntry = false
        this.capitalEntryDate = ""
        this.capitalEntryTime = ""
        this.capitalEntryReceiver = null
        this.capitalEntryReceiverNew = ""
        this.capitalEntryReceiverNewDisplay = false
        this.capitalEntryEmitter = null
        this.capitalEntryEmitterNew = ""
        this.capitalEntryEmitterNewDisplay = false
        this.capitalEntryMode = null
        this.capitalEntryModeNew = ""
        this.capitalEntryModeNewDisplay = false
      },
      newCapitalEntry() {
        this.addModalCapitalEntry = true
      },
      validCapitalEntry() {
        return this.capitalEntryName.trim().length >= 3 &&
          Regex.isPositiveNumber(this.capitalEntryAmount)
          && (this.capitalEntryEmitter != null || this.capitalEntryEmitterNew.trim().length >= 3)
          && (this.capitalEntryReceiver != null || this.capitalEntryReceiverNew.trim().length >= 3)
          && (this.capitalEntryMode != null || this.capitalEntryModeNew.trim().length >= 3)
      },
      getFiles(files) {
        return Promise.all(files.map(file => {
          var reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = (ev => {
              resolve(ev.target.result)
            })
            reader.readAsDataURL(file)
          })
        }))
      },
      async onSubmitAddCapitalEntry() {
        this.submittedCapitalEntry = true
        if (!this.validCapitalEntry())
          return
        
        this.fetchingCreateCapitalEntry = true
        
        const base64Files = await this.getFiles(this.capitalEntryFiles)
        // recuperation de l'emetteur
        const emitter = {
          isExist: this.capitalEntryEmitter ? true : false,
          user: this.capitalEntryEmitter || this.capitalEntryEmitterNew
        }
        // recuperation du beneficiaire
        const receiver = {
          isExist: this.capitalEntryReceiver ? true : false,
          user: this.capitalEntryReceiver || this.capitalEntryReceiverNew
        }
        // retrieving the old|new mode
        console.log(this.capitalEntryMode)
        const mode = {
          isExist: this.capitalEntryMode ? true : false,
          mode: this.capitalEntryMode || this.capitalEntryModeNew
        }
        
        let createOrUpdate = ""
        if(this.editModalCapitalEntry) createOrUpdate = 'update'
        else createOrUpdate = 'create'
        
        Api.post(`/exploitation/capital-entry/${createOrUpdate}`, {
          exploitationId: this.exploitationId,
          capitalEntryId: this.capitalEditId,
          name: this.capitalEntryName,
          amount: this.capitalEntryAmount,
          entryEmitter: emitter,
          entryReceiver: receiver,
          paymentMode: mode,
          date: this.capitalEntryDate + " " + this.capitalEntryTime,
          description: this.capitalEntryDescription,
          attachments: base64Files,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              if (createOrUpdate == 'create') {
                this.capitalEntries.push(res.data.data)
                this.addModalCapitalEntry = false
                Toast.success('Dépense enregistrée avec succès')
              }else {
                this.capitalEntries = this.capitalEntries.filter(ca => ca.id != this.capitalEditId)
                this.capitalEntries.push(res.data.data)
                this.addModalCapitalEntry = false
                Toast.success('Dépense modifiée avec succès')
              }
              this.refreshModeList()
              this.refreshPaymentModeList()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateCapitalEntry = false
          })
      },
      addCapitalEntryMode() {
        this.capitalEntryModeNewDisplay = true
      },
      addCapitalEntryEmitter() {
        this.capitalEntryEmitterNewDisplay = true
      },
      addReceiveEmitter() {
        this.capitalEntryReceiverNewDisplay = true
      },
      refreshModeList() {
        
        Api.get('/exploitation/member/all', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.capitalEntriesMembers = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      refreshPaymentModeList(){
        Api.get('/exploitation/payment-mode/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.capitalEntryModes = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
  
      },
      capitalEntryDetails(capitalEntry) {
  
        this.addModalCapitalEntry = true
        this.editModalCapitalEntry = true
        
        this.capitalEditId = capitalEntry.id
        this.capitalEntryName = capitalEntry.name
        this.capitalEntryAmount = capitalEntry.amount
        this.capitalEntryDescription = capitalEntry.description
        this.capitalEntryDate = capitalEntry.date.split(' ')[0].split('/').reverse().join('-')
        this.capitalEntryTime = capitalEntry.date.split(' ')[1]
        this.capitalEntryEmitter = this.capitalEntriesMembers.find(m => m.id == capitalEntry.entryReceiver.id
          && m.user == capitalEntry.entryReceiver.user)
        this.capitalEntryReceiver = this.capitalEntriesMembers.find(m => m.id == capitalEntry.entryEmitter.id
          && m.user == capitalEntry.entryEmitter.user)
        this.capitalEntryMode = this.capitalEntryModes.find(entry => entry.id == capitalEntry.paymentMode.id)
      },
      nextComposantToVisit(){
        this.$router.push('/exploitation/' + this.exploitationId + '/accounting/spents/list')
      }
    }
  }
</script>

<style scoped>
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }
</style>
